'use client';
import { SearchAirport } from '@components/landing-page/search-card/search-airport';
import { Icon } from '@icons/icons';
import { DateTimePicker } from '@ui/components';
import { getCookie } from 'cookies-next';
import { usePathname, useRouter } from 'next/navigation';
import aiportIllustration from '../../public/assets/AirplaneIllustration.svg';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { SearchAirportFieldsFragment } from '../../graphql/generated/graphql';
import { useTranslations } from 'next-intl';
import Button from '@ui/components/ui/button/Button';
import Image from 'next/image';

export const NotFoundComponent = ({
  countries,
}: {
  countries: Array<SearchAirportFieldsFragment>;
}) => {
  const router = useRouter();
  const t = useTranslations('search_card');
  const translation = useTranslations('not_found');
  const getDefaultDeparture = () => {
    const today = new Date();
    today.setHours(12, 0, 0, 0); // Set to 12:00 noon
    return today;
  };
  const pathname = usePathname();
  const currentRoute = pathname.replace(/^\//, '') || 'this page';

  const getDefaultArrival = (departureDate: Date) => {
    const defaultArrival = new Date(departureDate); // Clone the departure date
    defaultArrival.setDate(departureDate.getDate() + 1); // Add 1 day to the departure date
    defaultArrival.setHours(12, 0, 0, 0); // Set time to 12:00 noon
    return defaultArrival;
  };
  const [dateDeparture, setDateDeparture] = useState<Date | undefined>(
    getDefaultDeparture()
  );
  const [dateArrival, setDateArrival] = useState<Date | undefined>(
    getDefaultArrival(getDefaultDeparture()) // Pass the default departure date
  );
  const [selectedAirport, setSelectedAirport] = useState<string | null>();
  const [isDeparturePickerOpen, setIsDeparturePickerOpen] = useState(false);
  const [isArrivalPickerOpen, setIsArrivalPickerOpen] = useState(false);
  const [localeCalendar, setLocaleCalendar] = useState<string>('en');
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    const langCookie = getCookie('lang') as string; // Fetch 'lang' cookie
    if (langCookie) {
      setLocaleCalendar(langCookie); // Update localeCalendar dynamically
    }
  }, []);

  const handleAirportSelect = useCallback(
    (airportName: string) => {
      setSelectedAirport(airportName); // Update selected airport
      setIsDeparturePickerOpen(true);

      setIsArrivalPickerOpen(false);
    },
    [] // Include all necessary dependencies
  );

  const handleDateDepartureChange = (date: Date | undefined) => {
    setDateDeparture(date);
    if (date) {
      const newDefaultArrival = getDefaultArrival(date);
      if (!dateArrival || dateArrival < newDefaultArrival) {
        setDateArrival(newDefaultArrival); // Update the arrival date if it's invalid
      }
      setIsDeparturePickerOpen(false);
      setIsArrivalPickerOpen(true);
    }
  };

  const handleDateArrivalChange = (date: Date | undefined) => {
    if (date && date < dateDeparture!) {
      return;
    }
    setDateArrival(date);
    if (date) {
      setIsArrivalPickerOpen(false);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedAirport || !dateDeparture || !dateArrival) {
      // TODO: change this later to check all the fields are filled
      setError(t('search_airport.error'));
      return;
    }

    // Construct query parameters dynamically
    const queryParams = new URLSearchParams({
      airport: selectedAirport,
      departure: dateDeparture.toISOString(),
      arrival: dateArrival.toISOString(),
    });

    // Navigate to the new URL
    router.push(`/compare?${queryParams.toString()}`);
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center px-4 my-36">
      {/* Left Side: Airplane Illustration */}
      <div className="hidden lg:flex w-full lg:w-1/2 justify-center">
        <Image src={aiportIllustration} alt="404 Not Found" />
      </div>

      {/* Right Side: Error Message and Search */}
      <form
        action="#"
        onSubmit={handleSubmit}
        className="w-full lg:w-1/2 text-center lg:text-left space-y-6"
      >
        <div className="flex items-center justify-center lg:justify-start text-blue-600">
          <Icon name="StarIcon" size={6} />
          <span className="font-medium uppercase ml-2">{translation('error')}</span>
        </div>
        <h1 className="text-5xl md:text-6xl font-bold text-blue-600">404</h1>
        <p className="text-base md:text-lg text-gray-600">
          {translation('the_route')}{' '}
          <span className="font-bold text-gray-800">{currentRoute}</span>{' '}
          {translation('could_not_be_found')}
        </p>

        {/* Search Form */}
        <div className="shadow-md rounded-lg p-6 space-y-4 w-full max-w-lg mx-auto lg:mx-0">
          {/* Airport Dropdown */}
          <Suspense>
            <SearchAirport
              airports={countries}
              onAirportSelect={handleAirportSelect}
              isAutoScroll
              popoverSide="bottom"
            />
          </Suspense>

          {/* Date Pickers */}
          <div className="grid grid-cols-1 gap-4">
            <DateTimePicker
              localeCalendar={localeCalendar}
              side="bottom"
              timePlaceholder={t('time')}
              hourPlaceholder={t('hour')}
              value={dateDeparture}
              onChange={handleDateDepartureChange}
              calendarVariant="overlay"
              calendarTitle={t('departure.calendar_title')}
              title={t('departure.label')}
              displayDateFormat="dd-MM-yyyy"
              isOpen={isDeparturePickerOpen}
              position="departure"
            />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <DateTimePicker
              localeCalendar={localeCalendar}
              side="bottom"
              timePlaceholder={t('time')}
              hourPlaceholder={t('hour')}
              value={dateArrival}
              onChange={handleDateArrivalChange}
              calendarVariant="overlay"
              calendarTitle={t('arrival.calendar_title')}
              title={t('arrival.label')}
              displayDateFormat="dd-MM-yyyy"
              isOpen={isArrivalPickerOpen}
              position="arrival"
              minDate={dateDeparture}
            />
          </div>

          {error && (
            <p className="text-red-500 bg-red-100 border border-red-500 text-center py-2 px-4 rounded-lg">
              {error}
            </p>
          )}

          {/* Search Button */}
          <Button
            type="submit"
            icon="SearchRoundedIcon"
            variant="primary"
            size="large"
            iconColor="light"
            iconSize={6}
            radius="fullRounded"
            fullWidth
            isCenter
          >
            <span>{translation('search')}</span>
          </Button>
        </div>
      </form>
    </div>
  );
};
